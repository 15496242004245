const planeMeshParameters = [
  {
    rotation: {
      x: -Math.PI / 2
    },
    scale: 2,
    position: {
      x: 0,
      y: 40,
      z: 10
    }
  },
  {
    rotation: {
      x: Math.PI / 2
    },
    scale: 2,
    position: {
      x: 0,
      y: -40,
      z: 10
    }
  },
  {
    rotation: {
      y: Math.PI / 2
    },
    scale: 2,
    position: {
      x: 40,
      y: 0,
      z: 10
    }
  },
  {
    rotation: {
      y: -Math.PI / 2
    },
    scale: 2,
    position: {
      x: -40,
      y: 0,
      z: 10
    }
  },
  {
    rotation: {
      x: -Math.PI / 2
    },
    scale: 2,
    position: {
      x: 0,
      y: 40,
      z: -118
    }
  },
  {
    rotation: {
      x: Math.PI / 2
    },
    scale: 2,
    position: {
      x: 0,
      y: -40,
      z: -118
    }
  },
  {
    rotation: {
      y: Math.PI / 2
    },
    scale: 2,
    position: {
      x: 40,
      y: 0,
      z: -118
    }
  },
  {
    rotation: {
      y: -Math.PI / 2
    },
    scale: 2,
    position: {
      x: -40,
      y: 0,
      z: -118
    }
  }
]

export default planeMeshParameters
